html,
body {
  margin: 0;
  padding: 0;
  background-color: #edf0f2;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
*::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

*::-webkit-scrollbar-track {
  background-color: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 50px;
}
:root {
  font-size: calc(16 * 100vw / 1440);
}
:fullscreen {
  height: 100%;
  background-color: #fff;
  padding: 0 16px;
}
:-webkit-full-screen {
  height: 100%;
  background-color: #fff;
  padding: 0 16px;
}
.custom-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1200px) {
  :root {
    font-size: calc(16 * 100vw / 1200);
  }
}
@media screen and (max-width: 993px) {
  :root {
    font-size: calc(16 * 100vw / 993);
  }
}
@media screen and (max-width: 768px) {
  :root {
    font-size: calc(16 * 100vw / 768);
  }
}
@media screen and (max-width: 425px) {
  :root {
    font-size: calc(16 * 100vw / 425);
  }
}
@media screen and (max-width: 320px) {
  :root {
    font-size: calc(16 * 100vw / 320);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.slick-dots li.slick-active button:before {
  /* color: #ffffff !important; */
  background-color: #c72037 !important;
  width: 24px !important;
}

.slick-dots li button:before {
  color: #ffffff !important;
  background-color: grey;
  border-radius: 3px;
  height: 6px !important;
  width: 24px !important;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
